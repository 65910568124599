import * as React from "react";
import classnames from "classnames";

type Props = {
  className?: string;
  children: React.ReactElement;
};

function BackgroundCircles({ className, children }: Props) {
  return (
    <div className={classnames(className, "absolute top-0 left-0 z-background")}>{children}</div>
  );
}

export default BackgroundCircles;
