import type { Exchange } from "urql";
import { dedupExchange, errorExchange } from "urql";
import { cacheExchange } from "@urql/exchange-graphcache";
import type { IntrospectionData } from "@urql/exchange-graphcache/dist/types/ast";
import type { CacheExchangeOpts } from "@urql/exchange-graphcache/dist/types/types";
import { multipartFetchExchange } from "@urql/exchange-multipart-fetch";
import { devtoolsExchange } from "@urql/devtools";
import { relayPagination } from "@urql/exchange-graphcache/extras";
import * as Sentry from "@sentry/browser";
import type { SSRExchange } from "next-urql";
import { withUrqlClient } from "next-urql";

import type { GraphCacheConfig } from "@/gqlgen";
import schema from "@/schema.json";

export const cacheConfig: CacheExchangeOpts & GraphCacheConfig = {
  schema: schema as IntrospectionData,
  resolvers: {
    Query: {
      search: relayPagination(),
      enrolledPrograms: relayPagination(),
    },
  },
  updates: {
    Mutation: {
      deleteEntrySet: (res, _args, cache) => {
        if (res.deleteEntrySet?.deletedId != null) {
          cache.invalidate({ __typename: "EntrySet", id: res.deleteEntrySet.deletedId });
        }
      },
      deleteReview: (res, _args, cache) => {
        if (res.deleteReview?.deletedId != null) {
          cache.invalidate({ __typename: "Review", id: res.deleteReview.deletedId });
        }
      },
      deleteUser: (res, _args, cache) => {
        if (res.deleteUser?.deletedId != null) {
          cache.invalidate({ __typename: "User", id: res.deleteUser.deletedId });
        }
      },
      // Admin
      adminDeleteExercise: (res, _args, cache) => {
        if (res.adminDeleteExercise?.deletedId != null) {
          cache.invalidate({
            __typename: "ExerciseInfo",
            id: res.adminDeleteExercise.deletedId,
          });
        }
      },
      adminDeleteExerciseType: (res, _args, cache) => {
        if (res.adminDeleteExerciseType?.deletedId != null) {
          cache.invalidate({
            __typename: "ExerciseType",
            id: res.adminDeleteExerciseType.deletedId,
          });
        }
      },
      adminDeleteExerciseTypeGroup: (res, _args, cache) => {
        if (res.adminDeleteExerciseTypeGroup?.deletedId != null) {
          cache.invalidate({
            __typename: "ExerciseTypeGroup",
            id: res.adminDeleteExerciseTypeGroup.deletedId,
          });
        }
      },
      adminDeleteExerciseTypeOption: (res, _args, cache) => {
        if (res.adminDeleteExerciseTypeOption?.deletedId != null) {
          cache.invalidate({
            __typename: "ExerciseTypeOption",
            id: res.adminDeleteExerciseTypeOption.deletedId,
          });
        }
      },
      adminDeleteProgram: (res, _args, cache) => {
        if (res.adminDeleteProgram?.deletedId != null) {
          cache.invalidate({ __typename: "Program", id: res.adminDeleteProgram.deletedId });
        }
      },
      adminDeleteProgramActivity: (res, _args, cache) => {
        if (res.adminDeleteProgramActivity?.deletedId != null) {
          cache.invalidate({
            __typename: "ProgramActivity",
            id: res.adminDeleteProgramActivity.deletedId,
          });
        }
      },
      adminDeleteProgramBlock: (res, _args, cache) => {
        if (res.adminDeleteProgramBlock?.deletedId != null) {
          cache.invalidate({
            __typename: "ProgramBlock",
            id: res.adminDeleteProgramBlock.deletedId,
          });
        }
      },
      adminDeleteProgramExercise: (res, _args, cache) => {
        if (res.adminDeleteProgramExercise?.deletedId != null) {
          cache.invalidate({
            __typename: "ProgramExercise",
            id: res.adminDeleteProgramExercise.deletedId,
          });
        }
      },
      adminDeleteProgramTask: (res, _args, cache) => {
        if (res.adminDeleteProgramTask?.deletedId != null) {
          cache.invalidate({
            __typename: "ProgramTask",
            id: res.adminDeleteProgramTask.deletedId,
          });
        }
      },
      adminDeleteProgramSession: (res, _args, cache) => {
        if (res.adminDeleteProgramSession?.deletedId != null) {
          cache.invalidate({
            __typename: "ProgramSession",
            id: res.adminDeleteProgramSession.deletedId,
          });
        }
      },
      adminDeleteProgramSetGroup: (res, _args, cache) => {
        if (res.adminDeleteProgramSetGroup?.deletedId != null) {
          cache.invalidate({
            __typename: "ProgramSetGroup",
            id: res.adminDeleteProgramSetGroup.deletedId,
          });
        }
      },
      adminDeleteProgramWeek: (res, _args, cache) => {
        if (res.adminDeleteProgramWeek?.deletedId != null) {
          cache.invalidate({ __typename: "ProgramWeek", id: res.adminDeleteProgramWeek.deletedId });
        }
      },
    },
  },
  optimistic: {
    deleteEntrySet: (args) => {
      return {
        __typename: "DeleteEntrySetPayload",
        deletedId: args.input.id,
      };
    },
  },
};

export const withClient = withUrqlClient((ssrCache: SSRExchange) => ({
  url: "/api/graphql",
  exchanges: [
    process.env.NODE_ENV !== "production" && devtoolsExchange,
    dedupExchange,
    cacheExchange(cacheConfig),
    ssrCache,
    errorExchange({
      onError(err) {
        if (process.env.NODE_ENV !== "production") {
          // eslint-disable-next-line no-console
          console.log("%c[urql] ", "color:magenta;", "error:", err);
        }
        Sentry.captureException(err);
      },
    }),
    multipartFetchExchange,
  ].filter(Boolean) as Exchange[],
}));
